import {useMutation} from '@apollo/react-hooks';
import {Form, Modal, Typography} from 'antd';
import Axios from 'axios';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

import Button from '#root/components/shared/Button';
import TextInput from '#root/components/shared/form/TextInput';
import {noop} from '#root/helpers/componentHelpers';

import LocationInput from '../form/LocationInput';

const {Item: FormItem} = Form;

const newLocationMutation = gql`
mutation NewLocation($address: String!, $location: GeoPoint!, $title: String!) {
  createLocation(address: $address, location: $location, title: $title) {
    _id
    address
    location
    title
  }
}
`;

const NewLocationModal = ({visible, onCancel: handleCancel, onOk: handleOk}) => {
  const {formState: {isSubmitting}, handleSubmit, register, setValue, watch, errors} = useForm();
  const [addNewLocation] = useMutation(newLocationMutation);
  const [error, setError] = useState(null);

  useEffect(() => {
    register({name: 'address'}, {required: true});
    register({name: 'location'}, {required: true});
    register({name: 'title'}, {required: true});
  }, []);

  const setLocation = async address => {
    setValue('address', address);
    const {data: {results: [result]}} = await Axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAtZNL72xXYLuYnm7xmoMPLbA_drx7iC4Q`);

    if (!result) {
      return setValue('location', null);
    }

    setValue('location', {
      type: 'Point',
      coordinates: [result.geometry.location.lng, result.geometry.location.lat]
    });
  };

  const onSubmit = handleSubmit(async variables => {
    setError(null);

    try {
      const {data: {createLocation: location} = {}} = await addNewLocation({variables});

      handleOk(location);
    } catch (error) {
      setError(error);
    }
  });

  const location = watch('location');

  return (
    <Modal
      visible={visible}
      title="New Location"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" loading={isSubmitting} onClick={onSubmit}>
          Save
        </Button>
      ]}
    >
      <Form onSubmit={onSubmit}>
        <FormItem label="Title">
          <TextInput
            id="title"
            name="title"
            onChange={e => setValue('title', e.target.value)}
          />
          {errors.title ? (
            <Typography.Text type="danger" style={{marginTop: 0}}>
              {errors.title.type === 'required' ? 'Title is required.' : null}
            </Typography.Text>
          ) : null}
        </FormItem>
        <FormItem label="Address">
          <LocationInput
            onSelect={val => setLocation(val)}
          />
          {errors.address ? (
            <Typography.Text type="danger" style={{marginTop: 0}}>
              {errors.address.type === 'required' ? 'Select the address from the list.' : null}
            </Typography.Text>
          ) : null}
        </FormItem>
        <FormItem label="Location">
          <TextInput
            disabled
            value={`Lng: ${location ? location.coordinates[0] : ''} Lat: ${location ? location.coordinates[1] : ''}`}
            onChange={e => setValue('location', e.target.value)}
          />
          {errors.location ? (
            <Typography.Text type="danger" style={{marginTop: 0}}>
              {errors.location.type === 'required' ? 'Select the address from above to generate coordinates.' : null}
            </Typography.Text>
          ) : null}
        </FormItem>
        {error ? (
          <Typography.Text type="danger" style={{marginTop: 0}}>{error.message}</Typography.Text>
        ) : null}
      </Form>
    </Modal>
  );
};

NewLocationModal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  visible: PropTypes.bool
};

NewLocationModal.defaultProps = {
  onCancel: noop,
  onOk: noop,
  visible: false
};

export default NewLocationModal;
