import {Col, coll, Row} from 'antd';
import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = ({children}) => {
  return (
    <div className="container">
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

export default Container;
