import {Avatar, Col, Icon, Modal, Row} from 'antd';
import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import UnstyledButton from '#root/components/shared/Button/UnstyledButton';
import Gravatar from '#root/components/shared/Gravatar';
import AuthModals from '#root/components/shared/Modals/AuthModals';
import Context from '#root/context';

const HamburgBtn = styled(UnstyledButton)`
  font-size: 1.4em;
  padding: 0 0.5em;
  vertical-align: middle;

  @media (min-width: 380px) {
    font-size: 1.6em;
  }
`;

const HamburgIcon = styled(Icon)`
  color: #fff;
  font-size: inherit !important;
  margin-left: 2px;
  vertical-align: middle;

  @media (min-width: 380px) {
    vertical-align: middle;
  }
`;

const HeaderBtn = styled(UnstyledButton)`
  color: #fff;
  font-size: 0.8em;
  font-weight: 300;
  padding: 0 0.5em;

  &:active,
  &:focus,
  &:hover {
    color: #fff;
  }

  @media (min-width: 380px) {
    font-size: 1em;
  }
`;

const Logo = styled(Link)`
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  width: 100%;

  @media (min-width: 380px) {
    font-size: 1.2em;
  }
`;

const Wrapper = styled.div``;

export const Navbar = () => {
  const {user, toggleMenu, toggleLogin, toggleSignup} = useContext(Context);

  return (
    <Wrapper>
      <AuthModals redirect />
      <Row align="middle" type="flex" justify="space-between">
        <Col xs={8} sm={6}>
          <Logo to="/">Couch Poop</Logo>
        </Col>
        <Col xs={16} sm={18}>
          <Row align="middle" justify="end" type="flex" style={{flexWrap: 'nowrap'}}>
            {user ?
              <Col><Link to={`/user/${user.username}`}>{user.avatar ? <Avatar src={user.avatar}/> : <Gravatar email={user.email}/>}</Link></Col> :
              (
                <>
                  <Col>
                    <HeaderBtn
                      onClick={() => toggleSignup(true)}
                    >
                    Signup
                    </HeaderBtn>
                  </Col>
                  <Col>
                    <HeaderBtn
                      onClick={() => toggleLogin(true)}
                    >
                    Login
                    </HeaderBtn>
                  </Col>
                </>
              )}
            <Col>
              <HamburgBtn onClick={toggleMenu}>
                <HamburgIcon type="menu"/>
              </HamburgBtn>
            </Col>
          </Row>
          {/* </HamburgItem> */}
        </Col>
      </Row>
    </Wrapper>
  );
};
