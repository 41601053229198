import {useQuery} from '@apollo/react-hooks';
import {Badge, Calendar, List, Modal} from 'antd';
import gql from 'graphql-tag';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {Link, Route, Switch, useParams} from 'react-router-dom';
import styled from 'styled-components';

import Button from '#root/components/shared/Button';
import Context from '#root/context';

import ProfileHeader from './ProfileHeader';
import UserDashboard from './UserDashboard';
import UserPoop from './UserPoop';

const Buttons = styled.div`
  margin-bottom: 1rem;
`;

const Wrapper = styled.div``;

const userQuery = gql`
query UserQuery($username: String) {
  user(username: $username) {
    _id
    username
  }
}
`;

const User = ({match}) => {
  const {username} = useParams();
  const {data: {user} = {}} = useQuery(userQuery, {
    variables: {username}
  });

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      {user && user.username === username ? (
        <Buttons>
          <Link to={'/submit'}>
            <Button type="primary">
              Submit New Poo
            </Button>
          </Link>
        </Buttons>
      ) : null}
      <ProfileHeader showMeta={match.isExact} userId={user._id}/>
      <Switch>
        <Route exact path={`${match.path}`} component={UserDashboard}/>
        <Route exact path={`${match.path}/poops/:poopId`} component={UserPoop}/>
      </Switch>
    </Wrapper>
  );
};

User.propTypes = {
  match: PropTypes.object
};

export default User;
