import {Modal} from 'antd';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

import Context from '#root/context';

import Login from './Login';
import Signup from './Signup';

const AuthModals = ({redirect, redirectUrl}) => {
  const {state: {loginModal, signupModal}, toggleLogin, toggleSignup} = useContext(Context);

  return (
    <>
      <Modal
        footer={null}
        visible={signupModal}
        onCancel={() => toggleSignup(false)}

        title="Signup"
      >
        <Signup
          onUserCreate={() => toggleSignup(false)}
          onShowLogin={() => {
            toggleSignup(false);
            toggleLogin(true);
          }}
          redirect={redirect}
          redirectUrl={redirectUrl}
        />
      </Modal>
      <Modal
        footer={null}
        visible={loginModal}
        onCancel={() => toggleLogin(false)}
        title="Login"
      >
        <Login
          onUserLogin={() => toggleLogin(false)}
          onShowSignup={() => {
            toggleLogin(false);
            toggleSignup(true);
          }}
          redirect={redirect}
          redirectUrl={redirectUrl}
        />
      </Modal>
    </>
  );
};

AuthModals.propTypes = {
  redirectUrl: PropTypes.string,
  redirect: PropTypes.bool
};

AuthModals.defaultProps = {
  redirect: true
};

export default AuthModals;
