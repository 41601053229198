import md5 from 'md5';

const getGravatarUrlByEmail = email => {
  if (!email) {
    return;
  }

  const hash = md5(email.toLowerCase().trim());

  return `//gravatar.com/avatar/${hash}`;
};

export default getGravatarUrlByEmail;
