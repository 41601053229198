import {useQuery} from '@apollo/react-hooks';
import {Badge, Calendar, Modal} from 'antd';
import gql from 'graphql-tag';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';

import PoopList from '#root/components/Root/PoopList';

const poopsQuery = gql`
query PoopsByMonthQuery($month: Float!, $userId: String $year: Float!) {
  poopsByMonth(month: $month, userId: $userId, year: $year) {
    date {
      day
      month
      year
    }
    total
  }
}
`;

const Wrapper = styled.div``;

const PoopCal = ({userId}) => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [date, setDate] = useState({
    month: moment().month(),
    year: moment().year()
  });

  const {data: {poopsByMonth: poops} = {}} = useQuery(poopsQuery, {
    variables: {
      month: date.month,
      year: date.year,
      userId
    }
  });

  const handleClick = value => {
    setOpen(true);
    setModalData(value);
  };

  const handlePanelChange = value => {
    setDate({
      month: value.month(),
      year: value.year()
    });
  };

  const dateCellRender = value => {
    if (!poops || !poops.length) {
      return null;
    }

    const today = poops.find(poop => poop.date.day === value.date() && poop.date.month - 1 === value.month() && poop.date.year === value.year());

    if (!today) {
      return null;
    }

    const {total} = today;

    let type = 'default';

    if (total > 3) {
      type = 'error';
    } else if (total > 2) {
      type = 'warning';
    } else if (total > 1) {
      type = 'success';
    } else if (total > 0) {
      type = 'processing';
    }

    return <div style={{textAlign: 'center'}}><Badge status={type} text={total} /></div>;
  };

  return (
    <Wrapper>
      <Modal
        onCancel={() => setOpen(false)}
        title={modalData ? modalData.format('MM/DD/YYYY') : null}
        visible={open}
        footer={null}
      >
        <PoopList
          date={modalData}
          showUser={!userId}
        />
      </Modal>
      <Calendar
        dateCellRender={dateCellRender}
        onPanelChange={handlePanelChange}
        onSelect={handleClick}
      />
    </Wrapper>
  );
};

PoopCal.propTypes = {
  userId: PropTypes.string
};

export default PoopCal;
