import {Select as AntSelect} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const {Option} = AntSelect;

const StyledSelect = styled(AntSelect)``;

const Select = ({defaultValue, loading, options, onChange: handleChange, ...props}) => {
  return (
    <StyledSelect defaultValue={defaultValue} loading={loading} onChange={handleChange} {...props}>
      {options.map(option => {
        return (
          <Option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </Option>
        );
      })}
    </StyledSelect>
  );
};

Select.propTypes = {
  defaultValue: PropTypes.any,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.any.isRequired
  }))
};

export default Select;

