import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {Route} from 'react-router-dom';
import styled from 'styled-components';

import Context from '#root/context';

import AuthModals from '../Modals/AuthModals';

const Blur = styled.div`
  background: rgba(0, 0, 0, 0.2);
  height: calc(100vh - 64px);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
  -webkit-filter: url(#blur-filter);
  filter: url(#blur-filter);
  -webkit-filter: blur(3px);
  filter: blur(3px);
`;

const Wrapper = styled.div`
  height: 100%;
  margin: -3rem -1.5rem;
  position: relative;

  .blur-svg {
    display: none;
  }
`;

const meQuery = gql`
query Me {
  me {
    _id
  }
}
`;

const ProtectedRoute = props => {
  const {data: {me} = {}, loading} = useQuery(meQuery);
  const {state: {loginModal}, toggleLogin, user} = useContext(Context);

  if (loading) {
    return null;
  }

  if (me || user) {
    if (loginModal) {
      toggleLogin(false);
    }

    return <Route {...props}/>;
  }

  if (!me && !loading && !loginModal) {
    toggleLogin(true);
  }

  return (
    <Wrapper>
      <AuthModals redirect={false} />
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="blur-svg">
        <defs>
          <filter id="blur-filter">
            <feGaussianBlur stdDeviation="3"></feGaussianBlur>
          </filter>
        </defs>
      </svg>
      <Blur>
        <Route {...props}/>
      </Blur>
    </Wrapper>
  );
};

ProtectedRoute.propTypes = {

};

export default ProtectedRoute;
