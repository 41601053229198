import {Drawer, Menu} from 'antd';
import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import Context from '#root/context';

const NavDrawer = () => {
  const {state, toggleMenu} = useContext(Context);
  return (
    <Drawer
      closable={false}
      onClose={toggleMenu}
      placement="right"
      selectable="false"
      visible={state.menu}
    >
      <Menu
        style={{height: '100%', borderRight: 0}}
      >
        <Menu.Item>
          <Link to="/" onClick={toggleMenu}>Browse</Link>
        </Menu.Item>
      </Menu>
    </Drawer>
  );
};

export default NavDrawer;
