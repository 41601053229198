import {useQuery} from '@apollo/react-hooks';
import {faHome, faToiletPaper} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, Col, Icon as AntIcon, PageHeader, Row, Skeleton, Statistic} from 'antd';
import gql from 'graphql-tag';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import styled from 'styled-components';

import PoopCount from '#root/components/shared/PoopCount';
import Context from '#root/context';
import getGravatarUrlByEmail from '#root/helpers/getGravatarUrlByEmail';

const userQuery = gql`
  query UserQuery($userId: ID) {
    user(userId: $userId) {
      _id
      avatar
      email
      facebookId
      firstName
      lastName
      shortName
      averageWipes
      averageIntensity
      commonLocation {
        _id
        title
      }
      lastPoop {
        date
        time
      }
      # totalToday
      totalPoops
      username
    }
  }
`;

const Header = styled(PageHeader)`
  border: 1px solid ${props => props.theme.veryLightGrey};
  margin-bottom: 2rem;
`;

const FaIcon = styled(FontAwesomeIcon)``;
const Icon = styled(AntIcon)``;

const Stat = styled(Statistic)`
  ${FaIcon},
  ${Icon} {
    font-size: 0.8em !important;
  }
`;

const Wrapper = styled.div`
  background-color: #fff;
`;

const getLastPoopProps = lastPoop => {
  if (!lastPoop) {
    return {};
  }

  const now = moment();
  const lastPoopTime = moment(lastPoop.time);
  const lastPoopDate = moment(lastPoop.date);

  const last = moment(lastPoopDate).hours(lastPoopTime.hours()).minutes(lastPoopTime.minutes());

  const value = now.diff(last, 'hours');

  return {
    value,
    suffix: 'hours'
  };
};

const ProfileHeader = ({showMeta, userId}) => {
  if (!userId) {
    return null;
  }

  const {data: {user} = {}} = useQuery(userQuery, {
    variables: {userId},
    fetchPolicy: 'no-cache'
  });

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      <Header
        avatar={{src: user.avatar || getGravatarUrlByEmail(user.email)}}
        tags={<PoopCount count={user.totalPoops} />}
        title={user.username}
      >
        {showMeta ? (
          <Row gutter={[5, 5]}>
            {/* <Col xs={12} sm={6}>
              <Card>
                <Stat
                  prefix={<Icon type="calendar"/>}
                  title="Today"
                  value={user.totalToday || 0}
                />
              </Card>
            </Col> */}
            <Col xs={12} sm={6}>
              <Card>
                <Stat prefix={<Icon type="clock-circle" />} title="Last 💩" {...getLastPoopProps(user.lastPoop)} />
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card>
                <Stat
                  prefix={<Icon type="fire" />}
                  title="Avg Intensity"
                  value={user.averageIntensity || 0}
                  precision={2}
                />
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card>
                <Stat
                  prefix={<FaIcon icon={faToiletPaper} />}
                  title="Avg Wipes"
                  value={user.averageWipes || 0}
                  precision={2}
                />
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card>
                <Stat
                  prefix={<FaIcon icon={faHome} />}
                  title="Most Valuable Throne"
                  value={(user.commonLocation && user.commonLocation.title) || 0}
                  precision={2}
                />
              </Card>
            </Col>
          </Row>
        ) : null}
      </Header>
    </Wrapper>
  );
};

ProfileHeader.propTypes = {
  showMeta: PropTypes.bool,
  userId: PropTypes.string
};

export default ProfileHeader;
