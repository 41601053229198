import {useMutation} from '@apollo/react-hooks';
import {Form, Typography} from 'antd';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import Button from '#root/components/shared/Button';
import TextInput from '#root/components/shared/form/TextInput';
import Context from '#root/context';

const {Item: FI} = Form;

const FormItem = styled(FI)``;

const Error = styled(Typography.Paragraph)`
  height: 25px;
  margin-bottom: 0.74rem;
`;

const Wrapper = styled.div`
  ${FormItem} {
    margin-bottom: 0.74rem;
  }
`;

const registerMutation = gql`
mutation Register(
  $email: String!
  $firstName: String!
  $lastName: String
  $password: String!
  $username: String!
) {
  register(
    email: $email
    firstName: $firstName
    lastName: $lastName
    password: $password
    username: $username
  ) {
    _id
    email
    firstName
    lastName
    username
  }
}
`;

const Signup = ({onUserCreate: handleUserCreate, onShowLogin: handleShowLogin, redirect, redirectUrl}) => {
  const {formState: {isSubmitting}, handleSubmit, register, setValue} = useForm();
  const [error, setError] = useState(null);

  const [registerUser] = useMutation(registerMutation);
  const history = useHistory();
  const {setUser} = useContext(Context);

  const onSubmit = async variables => {
    setError(null);
    try {
      const {data: {register: user} = {}} = await registerUser({variables});

      setUser(user);

      if (redirect) {
        history.push(redirectUrl || `/user/${user.username}`);
      }

      handleUserCreate(user);
    } catch (error) {
      return setError(error);
    }
  };

  useEffect(() => {
    register({name: 'email'});
    register({name: 'firstName'});
    register({name: 'lastName'});
    register({name: 'password'});
    register({name: 'username'});
  }, []);

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem label="Email">
          <TextInput
            required
            disabled={isSubmitting}
            onChange={e => setValue('email', e.target.value)}
            type="email"
          />
        </FormItem>
        <FormItem label="Username">
          <TextInput
            required
            disabled={isSubmitting}
            onChange={e => setValue('username', e.target.value)}
          />
        </FormItem>
        <FormItem label="Password">
          <TextInput
            required
            disabled={isSubmitting}
            onChange={e => setValue('password', e.target.value)}
            type="password"
          />
        </FormItem>
        <FormItem label="First Name">
          <TextInput
            required
            disabled={isSubmitting}
            onChange={e => setValue('firstName', e.target.value)}
          />
        </FormItem>
        <FormItem label="Last Name">
          <TextInput
            disabled={isSubmitting}
            onChange={e => setValue('lastName', e.target.value)}
          />
        </FormItem>
        <FormItem>
          {error ? <Error type="danger">{error.message.replace('GraphQL error: ', '')}</Error> : null}
          <Button
            disabled={isSubmitting}
            onClick={handleSubmit}
            htmlType="submit"
            role="submit"
            type="primary"
          >
            Submit
          </Button>
          <Typography.Paragraph>Already have an account? <a href="#" onClick={handleShowLogin}>Login</a> </Typography.Paragraph>
        </FormItem>
      </Form>
    </Wrapper>
  );
};

Signup.propTypes = {
  onUserCreate: PropTypes.func.isRequired,
  onShowLogin: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
  redirect: PropTypes.bool
};

export default Signup;
