import {useQuery} from '@apollo/react-hooks';
import {faFire, faToiletPaper, faUmbrella} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, Col, PageHeader, Row, Statistic, Typography} from 'antd';
import axios from 'axios';
import gql from 'graphql-tag';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {GoogleMap, Marker, withGoogleMap} from 'react-google-maps';
import {useHistory, useParams} from 'react-router-dom';
import styled from 'styled-components';

import SharePoop from '#root/components/shared/SharePoop';

const Wrapper = styled.div`

`;

const Icon = styled(FontAwesomeIcon)``;

const Stat = styled(Statistic)`
  ${Icon} {
    font-size: 0.8em !important;
    margin-right: 0.5rem;
  }
`;

const Map = withGoogleMap(({lng, lat}) => {
  // Const [location, setLocation] = useState(null);
  // const [loading, setLoading] = useState(null);
  // UseEffect(() => {
  //   const getGeometry = async () => {
  //     setLoading(true);
  //     const {data: {results: [result]}} = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAtZNL72xXYLuYnm7xmoMPLbA_drx7iC4Q`);
  //     setLoading(false);

  //     if (!result) {
  //       return setLocation(null);
  //     }

  //     setLocation(result.geometry.location);
  //   };

  //   getGeometry();
  // }, []);

  // if (!location && !loading) {
  //   return <p>Not found!</p>;
  // }

  // if (!location) {
  //   return null;
  // }

  return (
    <GoogleMap
      defaultZoom={10.5}
      defaultCenter={{lng, lat}}
    >
      <Marker
        position={{lng, lat}}
      />
    </GoogleMap>
  );
});

Map.propTypes = {
  lng: PropTypes.number,
  lat: PropTypes.number,
  places: PropTypes.object
};

const poopQuery = gql`
query PoopQuery($_id: ID) {
  poop(_id: $_id) {
    _id
    comment
    date
    flushes
    intensity
    location {
      address
      location
      title
    }
    locationId
    time
    userId
    wipes
  }
}
`;

const UserPoop = () => {
  // Const [poop, setPoop] = useState(null);
  const history = useHistory();
  const params = useParams();
  const {data: {poop} = {}, loading} = useQuery(poopQuery, {variables: {_id: params.poopId}});

  if (!poop & !loading) {
    return <Typography.Title>Not Found</Typography.Title>;
  }

  if (!poop) {
    return null;
  }

  poop.date = moment(poop.date);
  poop.time = moment(poop.time);

  return (
    <Wrapper>
      <PageHeader
        onBack={() => history.replace(`/user/${params.username}`)}
        title={`Mud Monkey on: ${poop.date.format('MM/DD/YYYY')} @ ${poop.time.format('hh:mm a')}`}
      />
      <SharePoop poopId={poop._id} text="Copy Link" username={params.username}/>
      <Row gutter={[16, 16]} style={{marginTop: '1.5rem'}}>
        <Col span={8}>
          <Card>
            <Stat
              prefix={<Icon icon={faFire}/>}
              title="Intensity"
              value={poop.intensity}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Stat
              prefix={<Icon icon={faToiletPaper}/>}
              title="Wipes"
              value={poop.wipes}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Stat
              prefix={<Icon icon={faUmbrella}/>}
              title="Flushes"
              value={poop.flushes}
            />
          </Card>
        </Col>

        <Col xs={24} sm={12}>
          <Card title="Comments">
            <Typography.Paragraph>{poop.comment}</Typography.Paragraph>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card title="Location">
            {poop.location ? (
              <>
                <Typography.Paragraph>{poop.location.title}</Typography.Paragraph>
                {/* {poop.location && poop.location.location && poop.location.location.coordinates ? (
                  <Map
                    containerElement={<div style={{height: '400px'}} />}
                    mapElement={<div style={{height: '100%'}} />}
                    lat={poop.location.location.coordinates[1]}
                    lng={poop.location.location.coordinates[0]}
                  />
                ) : null} */}
              </>
            ) : null}
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default UserPoop;
