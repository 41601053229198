import {useQuery} from '@apollo/react-hooks';
import {Avatar, List} from 'antd';
import gql from 'graphql-tag';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import Button from '#root/components/shared/Button';
import Container from '#root/components/shared/Container';
import Gravatar from '#root/components/shared/Gravatar';
import LoggedIn from '#root/components/shared/helpers/LoggedIn';
import PoopCount from '#root/components/shared/PoopCount';

import PoopCal from '../../PoopCal';

const {Item: ListItem} = List;
const {Meta: ListItemMeta} = ListItem;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 40em;
`;

const Box = styled.div`
  background-color: #fff;
  box-shadow: 2px 2px 8px 0 rgba(0,0,0,.1);
  margin-top: 1.5rem;
  padding: 2.4rem;
`;

const usersQuery = gql`
{
  usersByPoopCount {
    _id
    avatar
    email
    firstName
    lastName
    username
    totalPoops
  }
}
`;

export const Home = () => {
  let {data: {usersByPoopCount: users = []} = {}} = useQuery(usersQuery);

  return (
    <Container>
      <>
        <Wrapper>
          <LoggedIn>
            <Link to={'/submit'}>
              <Button type="primary">
              Submit Your Poo
              </Button>
            </Link>
          </LoggedIn>
        </Wrapper>
        <PoopCal/>
        {/* <Wrapper>
          <LoggedIn>
            <Link to={'/submit'}>
              <Button type="primary">
              Submit Your Poo
              </Button>
            </Link>
          </LoggedIn>

          <Box>
            <List
              itemLayout="horizontal"
              size="medium"
            >
              {users.flatMap(user => (
                <ListItem
                  actions={[
                    <PoopCount count={user.totalPoops} key={0}/>
                  ]}
                  key={user._id}
                >
                  <ListItemMeta
                    avatar={user.avatar ? <Avatar src={user.avatar}/> : <Gravatar email={user.email}/>}
                    title={<Link to={`/user/${user.username}`}>{user.firstName} {user.lastName}</Link>}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Wrapper> */}
      </>
    </Container>
  );
};
