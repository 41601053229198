// Import PropTypes from 'prop-types';
import {Layout} from 'antd';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled(Layout.Footer)`
  text-align: center;
`;

const Footer = () => {
  return (
    <Wrapper>
      CouchPoop ©2020 Created by The Beef Boys
    </Wrapper>
  );
};

Footer.propTypes = {
};

export default Footer;
