import {useMutation, useQuery} from '@apollo/react-hooks';
import {Col, Form, Row} from 'antd';
import gql from 'graphql-tag';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import Button from '#root/components/shared/Button';
import DateInput from '#root/components/shared/form/DateInput';
import LocationInput from '#root/components/shared/form/LocationInput';
import NumInput from '#root/components/shared/form/NumInput';
import Select from '#root/components/shared/form/Select';
import Textarea from '#root/components/shared/form/Textarea';
import TextInput from '#root/components/shared/form/TextInput';
import TimeInput from '#root/components/shared/form/TimeInput';
import NewLocationModal from '#root/components/shared/Modals/NewLocation';
import Context from '#root/context';

const {Item: FormItem} = Form;

const Wrapper = styled.div``;

const myLocationsQuery = gql`
query MyLocations {
  myLocations {
    value: _id
    label: title
  }
}
`;

const newPoopMutation = gql`
mutation CreatePoop(
  $comment: String
  $date: Date!
  $flushes: Float!
  $intensity: Float!
  $locationId: String!
  $time: Date!
  $wipes: Float!
) {
  createPoop(
    comment: $comment
    date: $date
    flushes: $flushes
    intensity: $intensity
    locationId: $locationId
    time: $time
    wipes: $wipes
  ) {
    _id
  }
}
`;

export const NewPoop = () => {
  const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 8}
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 12}
    }
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 16,
        offset: 8
      }
    }
  };

  const {handleSubmit, register, setValue, watch} = useForm({
    defaultValues: {
      location: '',
      locationSelect: ''
    }
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [addNewPoop] = useMutation(newPoopMutation);
  const history = useHistory();
  const {user} = useContext(Context);

  const onSubmit = async variables => {
    const {data: {createPoop: poop} = {}} = await addNewPoop({variables});

    history.push(`/user/${user.username}/poops/${poop._id}`);
  };

  const {data: {myLocations: locations = []} = {}, loading: locationsLoading, refetch: refetchLocations} = useQuery(myLocationsQuery);

  useEffect(() => {
    register({name: 'date'});
    register({name: 'time'});
    register({name: 'locationId'});
    register({name: 'wipes'});
    register({name: 'flushes'});
    register({name: 'intensity'});
    register({name: 'comment'});
  }, []);

  return (
    <Wrapper>
      <NewLocationModal
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={async location => {
          await refetchLocations();
          setValue('locationId', location._id);
          setModalOpen(false);
        }}
      />
      <Row>
        <Col offset={4} span={16}><h1>New Turd</h1></Col>
      </Row>
      <Form {...formItemLayout} onSubmit={handleSubmit(onSubmit)}>
        <FormItem label="Date">
          <DateInput
            onChange={date => setValue('date', date.startOf('day').toDate())}
          />
        </FormItem>
        <FormItem label="Time">
          <TimeInput
            use12Hours
            onChange={time => {
              const startOfDay = time.clone().startOf('day');
              const diff = time.diff(startOfDay);

              setValue('time', startOfDay.add(diff));
            }}
            onOpenChange={open => {
              if (open) {
                window.document.body.style.overflow = 'hidden';
              } else {
                window.document.body.style.overflow = 'visible';
              }
            }}
          />
        </FormItem>
        <FormItem label="Location">
          <Select
            loading={locationsLoading}
            options={locationsLoading ? [] : locations}
            onChange={val => setValue('locationId', val)}
            value={watch('locationId')}
          />
          <Button
            onClick={() => setModalOpen(true)}
            size="small"
            type="dashed"
          >
            New Location
          </Button>
          {/* // <LocationInput
          //   onSelect={val => setValue('location', val)}
          // /> */}
        </FormItem>
        <FormItem label="Wipes per poop">
          <NumInput
            onChange={value => setValue('wipes', value)}
          />
        </FormItem>
        <FormItem label="Flushes per poop">
          <NumInput
            onChange={value => setValue('flushes', value)}
          />
        </FormItem>
        <FormItem label="Intensity">
          <NumInput
            min={0}
            max={5}
            placeholder="0-5"
            onChange={value => setValue('intensity', value)}
          />
        </FormItem>
        <FormItem label="Comments">
          <Textarea
            id="comment"
            name="comment"
            onChange={e => setValue('comment', e.target.value)}
            placeholder="Add comments that would make your step sister want to disassociate with her father..."
          />
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button onClick={handleSubmit} htmlType="submit" role="submit" type="primary">
            Submit
          </Button>
        </FormItem>
      </Form>

    </Wrapper>
  );
};
