import * as PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import PlacesAutocomplete, {Suggestion} from 'react-places-autocomplete';
import styled from 'styled-components';

import TextInput from './TextInput';

const Wrapper = styled(PlacesAutocomplete)``;

const LocationInput = ({onSelect: handleSelect}) => {
  const [value, setValue] = useState('');

  const handleChange = val => {
    setValue(val);
    // HandleSelect(val);
  };

  const handleSelectLocation = val => {
    setValue(val);
    handleSelect(val);
  };

  return (
    <Wrapper onChange={handleChange} onSelect={handleSelectLocation} value={value}>
      {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
        <React.Fragment>
          <TextInput
            {...getInputProps({
              autoComplete: 'false'
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading ? <div>Loading...</div> : null}
            {suggestions.map(suggestion => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              const style = suggestion.active ?
                {backgroundColor: '#fafafa', cursor: 'pointer'} :
                {backgroundColor: '#ffffff', cursor: 'pointer'};

              const spread = {
                ...getSuggestionItemProps(suggestion, {
                  className,
                  style
                })
              };

              return (
                <div {...spread} key={suggestion.id}>
                  <div>{suggestion.description}</div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

LocationInput.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default LocationInput;
