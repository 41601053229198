import React from 'react';
import styled from 'styled-components';

import Button from './';

const Btn = styled(Button)`
  background-color: transparent;
  border: 0;
  color: inherit;
  outline: none;

  &:active,
  &:focus,
  &:hover {
    background-color: transparent;
    color: inherit;
    outline: none;
  }
`;

const UnstyledButton = props => {
  return (
    <Btn
      {...props}
      default
    />
  );
};

export default UnstyledButton;
