import {useMutation} from '@apollo/react-hooks';
import {Form, Icon, Typography} from 'antd';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import Button from '#root/components/shared/Button';
import TextInput from '#root/components/shared/form/TextInput';
import Context from '#root/context';

const {Item: FI} = Form;

const FormItem = styled(FI)``;

const Wrapper = styled.div`
  ${FormItem} {
    margin-bottom: 0.74rem;
  }
`;

const loginMutation = gql`
mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    _id
    email
    firstName
    lastName
    username
  }
}
`;

const Signup = ({onUserLogin: handleUserLogin, onShowSignup: handleShowSignup, redirect, redirectUrl}) => {
  const {formState: {isSubmitting}, handleSubmit, register, setValue} = useForm();

  const [loginUser] = useMutation(loginMutation);
  const history = useHistory();
  const {setUser} = useContext(Context);

  const onSubmit = async variables => {
    const {data: {login: user} = {}} = await loginUser({variables});

    setUser(user);

    if (redirect) {
      history.push(redirectUrl || `/user/${user.username}`);
    }

    handleUserLogin(user);
  };

  useEffect(() => {
    register({name: 'email'});
    register({name: 'firstName'});
    register({name: 'lastName'});
    register({name: 'password'});
    register({name: 'username'});
  }, []);

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormItem>
          <TextInput
            required
            disabled={isSubmitting}
            onChange={e => setValue('email', e.target.value)}
            placeholder="Enter your email"
            prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />}
            type="email"
          />
        </FormItem>
        <FormItem>
          <TextInput
            required
            disabled={isSubmitting}
            onChange={e => setValue('password', e.target.value)}
            placeholder="Enter your password"
            prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
            type="password"
          />
        </FormItem>
        <FormItem>
          <Button disabled={isSubmitting} onClick={handleSubmit} htmlType="submit" role="submit" type="primary">
            Login
          </Button>
          <Typography.Paragraph>{'Don\'t'} have an account? <a href="#" onClick={handleShowSignup}>Signup now.</a> </Typography.Paragraph>
        </FormItem>
      </Form>
    </Wrapper>
  );
};

Signup.propTypes = {
  onUserLogin: PropTypes.func.isRequired,
  onShowSignup: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
  redirect: PropTypes.bool
};

export default Signup;
