import * as PropTypes from 'prop-types';
import React, {createContext, useState} from 'react';

import {noop} from '#root/helpers/componentHelpers';

const store = {
  user: null,
  setUser: noop,
  // Auth
  accessToken: null,
  setAccessToken: noop,
  isLoggedIn: noop,
  state: {
    menu: false,
    loginModal: false,
    signupModal: false
  },
  setState: noop,
  toggleMenu: noop,
  toggleLogin: noop,
  toggleSignup: noop
};

const Context = createContext(store);

export const ContextProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [state, setState] = useState(store.state);

  return (
    <Context.Provider
      value={{
        // User
        user,
        setUser: u => {
          setUser(u);
        },
        // Auth
        accessToken,
        setAccessToken,
        isLoggedIn: Boolean(accessToken),
        // State
        state,
        setState,
        toggleLogin: visible => {
          setState({
            ...state,
            signupModal: false,
            loginModal: typeof visible === 'undefined' ? !state.loginModal : visible
          });
        },
        toggleMenu: () => setState({
          ...state,
          menu: !state.menu
        }),
        toggleSignup: visible => {
          setState({
            ...state,
            loginModal: false,
            signupModal: typeof visible === 'undefined' ? !state.signupModal : visible
          });
        }
      }}
    >
      {children}
    </Context.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element])
};

export default Context;
