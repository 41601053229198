// Import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap-grid.css';

import React from 'react';
import {ApolloProvider} from 'react-apollo';
import {render} from 'react-dom';
import {FacebookProvider} from 'react-facebook';
import {ThemeProvider} from 'styled-components';

import graphqlClient from '#root/api/graphqlClient';
import Root from '#root/components/Root';
import {ContextProvider} from '#root/context';
import * as theme from '#root/styles/theme';

render(
  <ApolloProvider client={graphqlClient}>
    <ThemeProvider theme={theme}>
      <FacebookProvider appId="217608455935334">
        <ContextProvider>
          <Root />
        </ContextProvider>
      </FacebookProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
