import {useQuery} from '@apollo/react-hooks';
import {Layout} from 'antd';
import gql from 'graphql-tag';
import React, {useContext, useEffect} from 'react';
import {Profile} from 'react-facebook';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';

import Context from '#root/context';

import ProtectedRoute from '../shared/helpers/ProtectedRoute';
import Footer from './Footer';
import Header from './Header';
import Navbar from './Navbar';
import NavDrawer from './NavDrawer';
import Home from './views/Home';
import NewPoop from './views/NewPoop';
import User from './views/User';

const {Content} = Layout;

const GlobalStyles = createGlobalStyle`
  @import url(https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap);

  html {
    font-size: 62.5%;
  }

  body,
  html {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    min-height: 100%;
    padding: 0;
  }

  input {
    border: 1px solid ${props => props.theme.veryLightGrey};
    box-sizing: border-box;
    display: block;
    font-size: 0.9rem;
    padding: 0.25rem;
    width: 100%;
  }
`;

const MainContent = styled(Content)`
  margin: 3rem 0;
  padding: 0 1.5rem;
`;

const Wrapper = styled.div`
  display: block;
`;

const query = gql`
query Me {
  me {
    _id
    avatar
    email
    facebookId
    firstName
    lastName
    username
    shortName
  }
}
`;

export const Root = () => {
  const {setUser} = useContext(Context);

  const {data: {me: user} = {}} = useQuery(query);

  if (user) {
    setUser(user);
  }

  return (
    <Wrapper>
      <BrowserRouter>
        <GlobalStyles/>
        <Layout style={{minHeight: '100vh'}}>
          <Header>
            <Navbar/>
          </Header>
          <MainContent>
            <NavDrawer/>
            <Switch>
              <Route exact path="/" component={Home} />
              <ProtectedRoute exact path="/submit" component={NewPoop}/>
              <Route path="/user/:username" component={User}/>
            </Switch>
          </MainContent>
          <Footer/>
        </Layout>
      </BrowserRouter>
    </Wrapper>
  );
};
