import {useQuery} from '@apollo/react-hooks';
import {List} from 'antd';
import gql from 'graphql-tag';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import PoopCard from '#root/components/shared/cards/PoopCard';

const poopsQuery = gql`
query PoopsByDayQuery($date: String!, $userId: String) {
  poopsByDay(date: $date, userId: $userId) {
    _id
    comment
    date
    flushes
    intensity
    location {
      address
      title
    }
    time
    user {
      _id
      username
    }
    userId
    wipes
  }
}
`;

const PoopList = ({date, showUser, userId}) => {
  const {data: {poopsByDay: poops = []} = {}} = useQuery(poopsQuery, {
    variables: {
      date: date.startOf('day').format('MM-DD-YYYY'),
      userId
    }
  });

  return (
    <List>
      {poops.map(poop => (
        <List.Item key={poop._id}>
          <PoopCard
            poop={{
              ...poop,
              date: moment(poop.date),
              time: moment(poop.time),
              location: poop.location ? poop.location.title : null
              // Location: poop.location ? poop.location.title + ` (${poop.location.address})` : null
            }}
            user={showUser ? poop.user.username : null}
          />
        </List.Item>
      ))}
    </List>
  );
};

PoopList.propTypes = {
  date: PropTypes.instanceOf(moment),
  showUser: PropTypes.bool,
  userId: PropTypes.string
};

export default PoopList;
