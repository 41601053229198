import {Card, Typography} from 'antd';
import * as PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import poopShape from '#root/helpers/poopShape';

import SharePoop from '../SharePoop';

const Label = styled.span`
  font-weight: 400;
`;

const ListItem = styled.div`
  display: grid;
  font-weight: 300;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0.5em;
  margin-bottom: 0.75em;

  ${Label} {
    text-align: right;
  }
`;

const PoopCard = ({poop, user}) => {
  const url = `/user/${poop.user.username}/poops/${poop._id}`;

  let title = poop.time.format('hh:mm a');

  if (user) {
    title = (<><Link to={`/user/${poop.user.username}`}>{user}</Link> @ {title}</>);
  }

  return (
    <Card
      size="full"
      style={{width: '100%'}}
      extra={
        <>
          <SharePoop poopId={poop._id} username={poop.user.username}/>
        </>
      }
      title={title}
    >
      <ListItem>
        <Label>Location: </Label>
        <Typography.Text>{poop.location}</Typography.Text>
      </ListItem>
      <ListItem>
        <Label>Intensity: </Label>
        <Typography.Text>{poop.intensity}</Typography.Text>
      </ListItem>
      <ListItem>
        <Label>Wipes: </Label>
        <Typography.Text>{poop.wipes}</Typography.Text>
      </ListItem>
      <ListItem>
        <Label>Flushes: </Label>
        <Typography.Text>{poop.flushes}</Typography.Text>
      </ListItem>

      <Link to={url}>
        View Poo
      </Link>
    </Card>
  );
};

PoopCard.propTypes = {
  poop: poopShape,
  user: PropTypes.string
};

export default PoopCard;
