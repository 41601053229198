import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import PoopCal from '#root/components/Root/PoopCal';

const Wrapper = styled.div``;

const userQuery = gql`
query UserQuery($username: String) {
  user(username: $username) {
    _id
    username
  }
}
`;

const UserDashboard = () => {
  const {username} = useParams();
  const {data: {user} = {}} = useQuery(userQuery, {
    variables: {username}
  });

  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      <PoopCal
        userId={user._id}
      />
      {/* <div style={{height: 400}}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            // Height={300}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{r: 8}} />
            <Line type="monotone" dataKey="av" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </div> */}
    </Wrapper>
  );
};

export default UserDashboard;
