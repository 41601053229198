import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Count = styled.span``;

const I = styled.span`
  margin-right: 0.5em;
`;

const Wrapper = styled.span`
  align-items: center;
  display: inline-flex;
`;

const PoopCount = ({el, count}) => {
  return (
    <Wrapper as={el}>
      <I>💩</I>
      <Count>{count}</Count>
    </Wrapper>
  );
};

PoopCount.propTypes = {
  count: PropTypes.number,
  el: PropTypes.string
};

PoopCount.defaultProps = {
  count: null,
  el: 'span'
};

export default PoopCount;
