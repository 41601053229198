import moment from 'moment';
import PropTypes from 'prop-types';

const poopShape = PropTypes.shape({
  comments: PropTypes.string,
  date: PropTypes.instanceOf(moment),
  flushes: PropTypes.number,
  _id: PropTypes.string,
  intensity: PropTypes.number,
  location: PropTypes.string,
  time: PropTypes.instanceOf(moment),
  wipes: PropTypes.number
});

export default poopShape;
