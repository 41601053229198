import {Layout} from 'antd';
import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled(Layout.Header)`
  padding: 0 15px;
`;

const Header = ({children}) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

export default Header;
