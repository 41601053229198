import {Avatar} from 'antd';
import md5 from 'md5';
import PropTypes from 'prop-types';
import React from 'react';

import getGravatarUrlByEmail from '#root/helpers/getGravatarUrlByEmail';

const Gravatar = ({email}) => {
  const src = getGravatarUrlByEmail(email);

  return (
    <Avatar
      src={src || null}
      className="email"
    />
  );
};

Gravatar.propTypes = {
  email: PropTypes.string.isRequired
};

export default Gravatar;
