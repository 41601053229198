import {Icon, Tooltip} from 'antd';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import styled from 'styled-components';

import {copyToClipboard} from '#root/helpers/componentHelpers';

import UnstyledButton from '../Button/UnstyledButton';

const ShareUrl = styled.input`
  /* height: 0; */
  left: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;

  /* width: 0; */
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const SharePoop = ({poopId, text, username}) => {
  const input = useRef();
  const url = `/user/${username}/poops/${poopId}`;
  const href = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}${url}`;

  return (
    <Wrapper>
      <ShareUrl ref={input} type="text" defaultValue={href}/>
      <Tooltip
        placement="top"
        title="Copied to clipboard!"
        trigger="click"
      >
        <UnstyledButton onClick={() => copyToClipboard(input.current)}>
          {text}
          <Icon style={{marginLeft: 10}} type="share-alt"/>
        </UnstyledButton>
      </Tooltip>
    </Wrapper>

  );
};

SharePoop.propTypes = {
  poopId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};

export default SharePoop;
