import PropTypes from 'prop-types';
import {useContext} from 'react';

import Context from '#root/context';

const LoggedIn = ({children}) => {
  const {user} = useContext(Context);

  if (!user) {
    return null;
  }

  return children;
};

LoggedIn.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

export default LoggedIn;
